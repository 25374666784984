import React from 'react';
import Layout from '../components/Layout';
import Button from "../components/Button";
import {defineMessages, useIntl} from 'react-intl';
import arrow from "../assets/images/scrumboard/arrow.svg";
import junior from "../assets/images/junior.svg";
import line from "../assets/images/scrumboard/arrow3.svg";
import hotfix from "../assets/images/hotfix.svg";
import Img from "../components/Img";

const content = defineMessages({
    h1: {
        id: 'Header.Text',
        defaultMessage: '404'
    },
    h2: {
        id: 'Header.Text',
        defaultMessage: 'Page Not Found'
    },
    text: {
        id: 'Content.Text',
        defaultMessage: 'The page you are looking for doesn\'t exist.'
    },
    text2: {
        id: 'Content.Text',
        defaultMessage: 'or'
    },
    button: {
        id: 'Button.Text',
        defaultMessage: 'return home'
    },
});

const NotFoundPage = ({location}) => {
    const {formatMessage} = useIntl();
    return (
        <Layout pageName="not-found-page" location={location}>
            <section className='error-page'>
                <div className='container'>
                    <div className='content-container full-width '>
                        <h1>{formatMessage(content.h1)}</h1>
                        <h2>{formatMessage(content.h2)}</h2>
                        <p>{formatMessage(content.text)}</p>
                        <Button btnType='btn-primary btn-large' text={formatMessage(content.button)}
                                linkTo='/'/>
                        <h3>{formatMessage(content.text2)}</h3>
                    </div>
                    <div className='image-container arrow'>
                        <Img src={arrow} alt='' />
                    </div>
                    <div className='image-container junior'>
                        <Img src={junior} alt='' />
                    </div>
                    <div className='image-container line'>
                        <Img src={line} alt='' />
                    </div>
                    <div className='image-container hotfix'>
                        <Img src={hotfix} alt='' />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default NotFoundPage;